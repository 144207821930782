
































































import { Component, Vue } from "vue-property-decorator";
import Prism from "prismjs";
import "@/assets/themes/prism-darcula.css";

@Component
export default class Events extends Vue {
	mounted() {
		Prism.highlightAll();
	}
}
